import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import { PrimeVueConfiguration } from 'primevue/config'

const preset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{zinc.50}',
      100: '{zinc.100}',
      200: '{zinc.200}',
      300: '{zinc.300}',
      400: '{zinc.400}',
      500: '{zinc.500}',
      600: '{zinc.600}',
      700: '{zinc.700}',
      800: '{zinc.800}',
      900: '{zinc.900}',
      950: '{zinc.950}',
    },

    colorScheme: {
      light: {
        primary: {
          color: '{zinc.950}',
          inverseColor: '#ffffff',
          hoverColor: '{zinc.900}',
          activeColor: '{zinc.800}',
          background: 'transparent',
        },
        highlight: {
          background: '{zinc.950}',
          focusBackground: '{zinc.700}',
          color: '#ffffff',
          focusColor: '#ffffff',
        },
        content: {
          background: 'transparent',
        },
      },
      dark: {
        primary: {
          color: '{zinc.50}',
          inverseColor: '{zinc.950}',
          hoverColor: '{zinc.100}',
          activeColor: '{zinc.200}',
          background: 'transparent',
        },
        highlight: {
          background: 'rgba(250, 250, 250, .16)',
          focusBackground: 'rgba(250, 250, 250, .24)',
          color: 'rgba(255,255,255,.87)',
          focusColor: 'rgba(255,255,255,.87)',
        },
        content: {
          background: 'transparent',
        },
      },
    },
  },

  components: {
    datatable: {
      colorScheme: {
        light: {
          root: {
            background: 'transparent',
          },
          header: {
            background: 'transparent',
            cell: {
              background: 'transparent',
            },
          },
          row: {
            background: 'transparent',
            striped: {
              background: 'rgba(31,41,55,.03)',
            },
          },
        },
        dark: {
          root: {
            background: 'transparent',
          },
          header: {
            background: 'transparent',
            cell: {
              background: 'transparent',
            },
          },
          row: {
            background: 'transparent',
            striped: {
              background: 'rgba(31,41,55,.20)',
            },
          },
        },
      },
    },
    datepicker: {
      colorScheme: {
        light: {
          panel: {
            background: 'white',
          },
        },
        dark: {
          panel: {
            background: '{zinc.900}',
          },
        },
      },
    },
    tag: {
      colorScheme: {
        light: {
          primary: {
            color: 'white',
            background: '{zinc.500}',
          },
          danger: {
            color: 'white',
            background: '{red.600}',
          },
          success: {
            color: 'white',
            background: '{green.600}',
          },
          warn: {
            color: 'white',
            background: '{orange.600}',
          },
        },
        dark: {
          danger: {
            color: 'white',
            background: 'rgba(220, 38, 40, .2)',
          },
          success: {
            color: 'white',
            background: 'rgba(34, 197, 93, 0.2)',
          },
          warn: {
            color: 'white',
            background: 'rgba(234, 88, 12, .2)',
          },
        },
      },
    },
  },
})

export const primeVueOptions: PrimeVueConfiguration = {
  theme: {
    preset,
    options: {
      darkModeSelector: '&:not(.light)',
      cssLayer: {
        name: 'primevue',
        order:
          'tailwind-base, primevue, tailwind-components, tailwind-utilities',
      },
    },
  },
}
